import React, { useRef } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import { Logo } from '../types/widget.types'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import useWindowDimensions from '../utils/useWindowDimensions'

import XLeft from '../img/x-left.svg'
import XRight from '../img/x-right.svg'
import LogoEnterprise from '../img/divize-1-4.svg'
import LogoSales from '../img/divize-1-2.svg'
import LogoWeb from '../img/divize-1-1.svg'
import Tech1 from '../img/1-asp.svg'
import Tech2 from '../img/2-react.svg'
import Tech3 from '../img/3-xperience.svg'
import Tech4 from '../img/4-kontent.svg'
import Tech5 from '../img/5-orchard.svg'
import Tech6 from '../img/6-g.svg'
import { getLocalizedUrl } from '../utils/localeURL'
import { Icon } from '../components/builders/icon-builder'

interface CTAProps {
  contentItemId: string
  puxDesignHPCTABTNText: string
  puxDesignHPCTADescription: {
    html: string
  }
  puxDesignHPCTALink: string
  newLink: {
    internal: [boolean]
    url: [string]
    text: [string]
  }
  bag: {
    contentItems: [Logo]
  }
  puxDesignIconSelector: {
    puxDesignIconSelectorIcon: string
    puxDesignIconSelectorColor: string
  }
}

const renderLogo = (logo: Logo) => {
  if (logo.logoLink?.url[0] != null) {
    if (logo.logoLink.internal[0]) {
      return (
        <Link to={getLocalizedUrl(logo.logoLink?.url[0])} className="hp-cta-logo">
          <img src={logo.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]} alt={logo.displayText}></img>
        </Link>
      )
    } else {
      return (
        <a href={logo.logoLink?.url[0]} className="hp-cta-logo" target="_blank" rel="noopener">
          <img src={logo.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]} alt={logo.displayText}></img>
        </a>
      )
    }
  } else {
    return (
      <div className="hp-cta-logo">
        <img src={logo.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]} alt={logo.displayText}></img>
      </div>
    )
  }
}

const renderHPCTA = (cta: CTAProps) => (
  <div className="hp-cta-wrap">
    <Link to={getLocalizedUrl(cta.newLink?.url[0])} className="hp-cta">
      <div className="hp-cta-icon">
        <Icon
          id={cta.puxDesignIconSelector.puxDesignIconSelectorIcon}
          color={cta.puxDesignIconSelector.puxDesignIconSelectorColor}
        />
      </div>
      <div
        className="hp-cta-description"
        dangerouslySetInnerHTML={{ __html: cta.puxDesignHPCTADescription.html?.replace(/font-size: 1rem;/g, ``) }}
      ></div>
      {cta.newLink?.text.length && (
        <div className="hp-cta-btn-wrap">
          <div className="btn btn-primary">{cta.newLink?.text[0]}</div>
        </div>
      )}
    </Link>
    <div className="hp-cta-logos">
      {cta.bag.contentItems.map((logo) => (
        <div className="logo-wrap">{renderLogo(logo)}</div>
      ))}
    </div>
  </div>
)

const HomePage = ({ data, pageContext }: any) => {
  const page = data.orchard.homePage[0]

  const { scrollY } = useViewportScroll()

  const { width, height } = useWindowDimensions()

  const leftX = useTransform(scrollY, [0, 800], [`0%`, `100%`])

  const rightX = useTransform(scrollY, [0, 800], [`0%`, `-100%`])

  const rightOpacity = useTransform(scrollY, [0, 300], [`1`, `0.1`])

  const leftOpacity = useTransform(scrollY, [0, 500], [`0.1`, `0.05`])

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />

      <section className="bg-white">
        <div className="top-image-wrap">
          <motion.div
            className="top-image-left"
            animate={{ x: [0, 20, 0, 0] }}
            transition={{ duration: 6, delay: 0.5, repeat: Infinity }}
          >
            <motion.img src={XRight} style={{ x: leftX, opacity: leftOpacity, rotate: `180deg`, display: `block` }} />
          </motion.div>
          <motion.div
            className="top-image-right"
            animate={{ x: [0, -20, 0, 0] }}
            transition={{ duration: 6, delay: 0.5, repeat: Infinity }}
          >
            <motion.img src={XRight} style={{ x: rightX, opacity: rightOpacity }} />
          </motion.div>
        </div>

        <div className="hp-intro">
          <h1 dangerouslySetInnerHTML={{ __html: page.homePageHeading }}></h1>
          <div
            className="perex"
            dangerouslySetInnerHTML={{ __html: page.homePagePerex.html?.replace(/font-size: 1rem;/g, ``) }}
          ></div>
        </div>

        <div className="pux-container">
          <div className="hp-ctas">{page.bag.contentItems.map((cta: CTAProps) => renderHPCTA(cta))}</div>
        </div>
      </section>
      <section className="bg-black triangle-cross">
        <div className="pux-container">
          <div className="technology-seo">
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: page.homePageTechnologyText.html?.replace(/font-size: 1rem;/g, ``) }}
            ></div>
            <div className="icons">
              <div className="icon">
                <img src={Tech1} alt="ASP.NET Core"></img>
              </div>
              <div className="icon">
                <img src={Tech2} alt="React.js"></img>
              </div>
              <div className="icon">
                <img src={Tech3} alt="Kentico Xperience"></img>
              </div>
              <div className="icon">
                <img src={Tech4} alt="Kontent"></img>
              </div>
              <div className="icon">
                <img src={Tech5} alt="Orchard Core"></img>
              </div>
              <div className="icon">
                <img src={Tech6} alt="Gatsby.js"></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="hp-widgets">
        <WidgetBuilder widgetBuilderData={pageContext.widgets} />
      </div>
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      homePage(first: 1, where: { path: $pagePath }) {
        homePageHeading
        homePagePerex {
          html
        }
        homePageTechnologyText {
          html
        }
        localization {
          localizations {
            ... on Orchard_HomePage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        bag {
          contentItems {
            contentItemId
            ... on Orchard_PuxDesignHPCTA {
              puxDesignHPCTADescription {
                html
              }
              newLink {
                internal
                url
                text
              }
              puxDesignHPCTABTNText
              puxDesignIconSelector {
                puxDesignIconSelectorIcon
                puxDesignIconSelectorColor
              }
              bag {
                contentItems {
                  displayText
                  contentItemId
                  ... on Orchard_PuxDesignLogo {
                    contentItemId
                    logoLink {
                      internal
                      url
                      text
                    }
                    puxMediaSelector {
                      puxMediaSelectorImage {
                        resizePaths(width: 400, mode: "Min")
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
